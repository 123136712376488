/* You can add global styles to this file, and also import other style files */
//Custom Checkbox
.styled-input-single {
  position: relative;
  padding: 10px 0 20px 40px;
  text-align: left;

  label {
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 35%;
      border-radius: 50%;
    }
    &:before {
      left: 0;
      width: 30px;
      height: 30px;
      margin: -15px 0 0;
      background: #f7f7f7;
      box-shadow: 0 0 1px grey;
    }
    &:after {
      left: 5px;
      width: 20px;
      height: 20px;
      margin: -10px 0 0;
      opacity: 0;
      background: #37b2b2;
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:before {
      }
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}
input[type="radio"],
input[type="checkbox"] {
  &:disabled + label {
    text-decoration: line-through;
  }
}

.styled-input--rounded {
  label {
    &:before {
      border-radius: 10px;
    }
    &:after {
      border-radius: 6px;
    }
  }
}

.custom-button {
  background-color: transparent;
  border: none;
  font-size: 18px !important;
}
.custom-button:focus {
  outline: none;
}

//Status-Circle
.status-circle {
  height: 20px;
  width: 20px;
  border: 5px solid rgb(255, 2, 2);
  background-color: #fff;
  border-radius: 50%;
  z-index: 5;
  transition: all 0.2s;
  cursor: pointer;
}
.status-circle.active {
  border: 5px solid #16e052 !important;
}
.status-circle:hover {
  transform: scale(1.2);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.nc-icon {
  font-size: 20px !important;
  cursor: pointer;
}
.nc-icon:focus {
  outline: none;
}
.nc-icon:hover {
  transform: scale(1.2);
  font-weight: bolder;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: transparent !important;
}

.table-responsive {
  overflow: hidden !important;
}
.custom-tbody {
  tr {
    cursor: pointer;
  }
  tr:focus {
    outline: none;
  }
  tr:hover {
    background: #dbf1f1;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
    transform: scale(1.01);
  }
}
.query:hover {
  background: #dbf1f1;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
  transform: scale(1.01);
}
